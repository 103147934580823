import React from "react";
import styled from "styled-components";
import Resources from '../../resources/mlp-resources.json';
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.css"

const PrivacyPolicyTitle = styled.label`
  font-family: var(${Resources.privacy_policy.title.font});
  font-size: ${Resources.privacy_policy.title.size};
  color: var(${Resources.privacy_policy.title.color});
  text-transform: uppercase;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.privacy_policy.title.size_small};
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.privacy_policy.title.size_med};
  }
`;

const PrivacyPolicySubtitle = styled.label`
  font-family: var(${Resources.privacy_policy.subtitle.font});
  font-size: ${Resources.privacy_policy.subtitle.size};
  color: var(${Resources.privacy_policy.subtitle.color});
  text-transform: uppercase;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.privacy_policy.subtitle.size_small};
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.privacy_policy.subtitle.size_med};
  }
`;

const PrivacyPolicyDescription = styled.p`
  margin-top: 40px;
  font-family: var(${Resources.privacy_policy.description.font});
  font-size: ${Resources.privacy_policy.description.size};
  color: var(${Resources.privacy_policy.description.color});
  line-height: ${Resources.privacy_policy.description.line_height};
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.privacy_policy.description.size_small};
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.privacy_policy.description.size_med};
  }
`;

const PrivacyPolicyDescriptionItem = styled.p`
  margin-top: 10px;
  font-family: var(${Resources.privacy_policy.description.font});
  font-size: ${Resources.privacy_policy.description.size};
  color: var(${Resources.privacy_policy.description.color});
  line-height: ${Resources.privacy_policy.description.line_height};
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.privacy_policy.description.size_small};
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.privacy_policy.description.size_med};
  }
`;

const AvisoDePrivacidad = () => (
  <Layout fixedNavbar={true}
          location="aviso-de-privacidad"
          showContact={false}>
    <SEO title={Resources.privacy_policy.title.label} />
    <div className="container">
      <div style={{ marginTop: '140px' }}></div>
      <PrivacyPolicyTitle>
        {Resources.privacy_policy.title.label}
      </PrivacyPolicyTitle>
      <PrivacyPolicyDescription>
        <PrivacyPolicySubtitle>{Resources.privacy_policy.subtitle_1}</PrivacyPolicySubtitle>, {Resources.privacy_policy.description_1}
      </PrivacyPolicyDescription>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_2}
      </PrivacyPolicyDescription>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_3}
      </PrivacyPolicyDescription>
      <br/>
      <br/>
      <PrivacyPolicySubtitle>
        {Resources.privacy_policy.subtitle_2}
      </PrivacyPolicySubtitle>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_4}
      </PrivacyPolicyDescription>
      <div style={{ paddingLeft: '45px' }}>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_5}
        </PrivacyPolicyDescriptionItem>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_6}
        </PrivacyPolicyDescriptionItem>
      </div>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_7}
      </PrivacyPolicyDescription>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_8}
      </PrivacyPolicyDescription>
      <div style={{ paddingLeft: '45px' }}>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_9}
        </PrivacyPolicyDescriptionItem>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_10}
        </PrivacyPolicyDescriptionItem>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_11}
        </PrivacyPolicyDescriptionItem>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_12}
        </PrivacyPolicyDescriptionItem>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_13}
        </PrivacyPolicyDescriptionItem>
      </div>
      <br/>
      <br/>
      <PrivacyPolicySubtitle>
        {Resources.privacy_policy.subtitle_3}
      </PrivacyPolicySubtitle>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_14}
      </PrivacyPolicyDescription>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_15}
      </PrivacyPolicyDescription>
      <div style={{ paddingLeft: '45px' }}>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_16}
        </PrivacyPolicyDescriptionItem>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_17}
        </PrivacyPolicyDescriptionItem>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_18}
        </PrivacyPolicyDescriptionItem>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_19}
        </PrivacyPolicyDescriptionItem>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_20}
        </PrivacyPolicyDescriptionItem>
      </div>
      <br/>
      <br/>
      <PrivacyPolicySubtitle>
        {Resources.privacy_policy.subtitle_4}
      </PrivacyPolicySubtitle>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_21}
      </PrivacyPolicyDescription>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_22}
      </PrivacyPolicyDescription>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_23}
      </PrivacyPolicyDescription>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_24}
      </PrivacyPolicyDescription>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_25}
      </PrivacyPolicyDescription>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_26}
      </PrivacyPolicyDescription>
      <div style={{ paddingLeft: '45px' }}>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_27}
        </PrivacyPolicyDescriptionItem>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_28}
        </PrivacyPolicyDescriptionItem>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_29}
        </PrivacyPolicyDescriptionItem>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_30}
        </PrivacyPolicyDescriptionItem>
        <PrivacyPolicyDescriptionItem>
          {Resources.privacy_policy.description_31}
        </PrivacyPolicyDescriptionItem>
      </div>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_32}
      </PrivacyPolicyDescription>
      <PrivacyPolicyDescription>
        {Resources.privacy_policy.description_33}
      </PrivacyPolicyDescription>
      <div style={{ marginTop: '140px' }}></div>
    </div>
  </Layout>
);

export default AvisoDePrivacidad;
